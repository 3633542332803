import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'
import './../assets/css/pages/index.scss'

const Layout = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={cx('Layout', { [className]: className })}>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta http-equiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#40b2b5" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
          <meta
            name="google-site-verification"
            content="pvdlatmPkcFM9nJ0SNYcbt8eMjWWp57O8ntgTO8H4UU"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#40b2b5" />
          <meta
            name="description"
            content="Peace of mind starts with Meerkat. Monitor and get real-time alerts for your cryptocurrency wallets."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@meerkat" />
          <meta
            name="twitter:title"
            content="Meerkat Blockchain Address Monitor"
          />
          <meta
            name="twitter:description"
            content="Peace of mind starts with Meerkat. Monitor and get real-time alerts for your cryptocurrency wallets."
          />
          <meta
            name="twitter:image"
            content="https://www.meerkat.watch/meta-image.jpg"
          />
          <meta name="twitter:image:alt" content="Meerkat" />
          <meta name="og:title" property="Meerkat Blockchain Address Monitor" />
          <meta name="og:type" property="website" />
          <meta name="og:url" property="https://www.meerkat.watch" />
          <meta
            name="og:description"
            property="Peace of mind starts with Meerkat. Monitor and get real-time alerts for your cryptocurrency wallets."
          />
          <meta
            name="og:image"
            property="https://www.meerkat.watch/meta-image.jpg"
          />
          <meta name="og:image:width" property="2400" />
          <meta name="og:image:height" property="1260" />
        </Helmet>

        {children}
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
