import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Navbar from '../components/shared/Navbar'
import Verify from 'src/components/pages/Verify/Verify'

const VerifyPage = (props) => (
  <Layout>
    <Helmet title="Meerkat - Verify Subscription" />
    <Navbar />
    <Verify location={props.location} />
  </Layout>
)

export default VerifyPage
