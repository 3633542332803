import React from 'react'
import classNames from 'classnames/bind'
import { LinkButton, Section } from 'src/components/shared'
import { parse } from 'qs'
import axios from 'axios'
import Landscape from 'src/assets/images/landscape-confirmation.svg'
import styles from './Verify.module.scss'

const cx = classNames.bind(styles)

class Verify extends React.Component {
  constructor(props) {
    super(props)

    const { id: subscriptionId } = parse(props.location.search, {
      ignoreQueryPrefix: true,
    })

    this.state = {
      subscriptionId,
      isSuccessful: null,
      data: {},
    }
  }

  componentDidMount() {
    axios
      .post(`/api/v0/subscriptions/${this.state.subscriptionId}/verify`)
      .then(({ data }) => {
        this.setState({ isSuccessful: true, data })
      })
      .catch(() => this.setState({ isSuccessful: false }))
  }

  render() {
    const { data } = this.state
    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <Section className={styles.container}>
            {this.state.isSuccessful === true && (
              <React.Fragment>
                <h1 className={styles.title}>Active Monitoring is Live!</h1>
                <span className={styles.text}>
                  The Meerkats are now monitoring{' '}
                  <span className={styles.name}>{data.name}</span> around the
                  clock.{' '}
                </span>
                <span className={styles.text}>
                  If any transactions are initiated to or from{' '}
                  <strong>{data.address}</strong> you will be notified via
                  email.
                </span>
                <LinkButton className="Btn Btn--medium Btn--primary" to="/">
                  Watch Another Address
                </LinkButton>
              </React.Fragment>
            )}

            {this.state.isSuccessful === false && (
              <React.Fragment>
                <h1 className={cx('title')}>
                  Failed to Activate Subscription.
                </h1>
                <span className={styles.text}>
                  Sorry, we experienced an error. If issues persist, please
                  contact support.
                </span>
                <LinkButton className="Btn Btn--medium Btn--primary" to="/">
                  Try Again
                </LinkButton>
              </React.Fragment>
            )}
            
          </Section>
          <div className={styles.imgContainer}>
            <img
              alt="meerkats landscape"
              className={styles.image}
              src={Landscape}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Verify
